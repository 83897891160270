// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { chooseAdditionalThemes, dashboardMultiSessionSelect, enableSessionReadOnlySharing, enableSessionSharing, guardantBuild, upennBuild, productFocus, noBackend, providenceBuild, logoutAfterInactivityMinutes, noProfile } from '../settings'

const clinicianView = productFocus === 'clinician'

export const defaultFeatureToggles = {
  chooseAdditionalThemes,
  clinicianView,
  dashboardMultiSessionSelect,
  enableSessionReadOnlySharing,
  enableSessionSharing,
  guardantBuild,
  logoutAfterInactivityMinutes,
  noBackend,
  noProfile,
  providenceBuild,
  upennBuild,
  researcherView: productFocus === 'researcher',
}
